'use strict';

angular.module('windmanagerApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('repair', {
        url: '/repair/:id',
        template: '<repair></repair>',
        authenticate: true,
        data: {
          bodyClass: 'repair'
        }
      });
  });
